.dashboard-drawer-btn {
  display: none;
}

.profile-sidebar {
  overflow: hidden;
}
.dashboard-menu ul {
  color: #363636;
  font-size: 14px;
  line-height: 14px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
.dashboard-menu ul li {
  line-height: inherit;
}
.dashboard-menu > ul > li {
  border-bottom: 1px solid #dadada;
  position: relative;
}
.dashboard-menu > ul > li:last-child {
  border-bottom: 0;
}
.dashboard-menu ul li a span,
.dashboard-menu ul li a i {
  display: inline-block;
  vertical-align: middle;
}
.dashboard-menu > ul > li > a {
  color: var(--textLight);
  display: block;
  padding: 16px 20px;
  transition: 0.2s ease-in;
}
.dashboard-menu > ul > li:hover > a,
.dashboard-menu > ul > li.active > a {
  color: var(--primaryColor);
}
.dashboard-menu > ul > li:hover > a {
  transform: scale(1.05);
}
.dashboard-menu > ul > li > a.active {
  color: var(--primaryColor) !important;
  background: var(--bgHoverColor);
  border: 1px solid var(--borderColor);
  border-left: 1.5px solid var(--borderColor);
  border-right: 1.5px solid var(--borderColor);
}

.dashboard-menu ul li a .icon {
  font-size: 16px;
  margin-right: 10px;
  width: 16px;
}
.profile-info {
  display: inline-block;
  margin: 0 0 15px;
  width: auto;
  padding: 8px;
  border-radius: 50%;
}
.profile-info img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid var(--borderColor);
  object-fit: cover;
  margin-bottom: 8px;
}
.profile-details h5 {
  font-size: 18px;
  font-weight: 700;
  color: var(--textColor);
}
.profile-details p {
  font-size: 14px;
  color: var(--textLight);
}

@media (max-width: 768px) {
  .dashboard-drawer-btn {
    position: fixed;
    top: 70px;
    right: 15px;
    z-index: 1000;
    overflow: hidden;
    font-size: 1.15rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #b9122c !important;
  }

  .dashboard-menu-sidebar{
    display: none;
  }
}

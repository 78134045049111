.login-form {
  padding: 0;
  margin: 0;
  margin-top: 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 6rem;
  margin-bottom: 2rem;
  background: #fff;
}

.login-form .login-container {
  position: relative;
  width: 70vw;
  height: 650px;
  background: var(--bgColor);
  border: 1.5px solid var(--borderLight);
  border-bottom: none;
  border-radius: 15px;
  box-shadow: var(--materialShadow);
  overflow: hidden;
}

.login-form .login-container .pageCloseBtn {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  z-index: 33;
  color: var(--textLight);
  background: var(--bgColor);
  border: 1.5px solid var(--textLight);
  text-align: center;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form .login-container .pageCloseBtn:hover {
  background: #fff;
  color: #8c1815;
  border-color: #8c1815;
}

.login-form .login-container button:disabled {
  background: rgb(163, 163, 163);
}

.login-form .login-container .password-validatity {
  margin: 10px 0;
}

.login-form .login-container .password-validatity p {
  line-height: 1px;
  margin-bottom: 2px;
  font-size: 0.75rem;
  font-weight: 500;
}

.login-form .login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #b57b84, #f0a5b1);
  z-index: 6;
  transform: translateX(100%);
  transition: 1s ease-in-out;
}

.login-form .login-container .signIn-signUp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 5;
}

.login-form .login-container form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-width: 238px;
  padding: 0 10px;
}

.login-form .login-container form .sign-in-form {
  opacity: 1;
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}

.login-form .login-container form .sign-up-form {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}

.login-form .login-container .title {
  font-size: 1.5rem;
  color: var(--primaryColor);
  margin-bottom: 10px;
  text-transform: uppercase;
}

.login-form .login-container .input-field {
  width: 90%;
  height: 42px;
  background: var(--bgColor);
  margin: 6px 0;
  border: 1.5px solid var(--borderLight);
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.login-form .login-container .input-field i {
  flex: 1;
  text-align: center;
  color: var(--textLight);
  padding: 8px 0 8px 8px;
  font-size: 0.9rem;
}

.login-form .login-container .input-field input {
  flex: 8;
  background: none;
  padding: 4px 20px 4px 4px;
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(textColor);
}

.login-form .login-container .btn {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: var(--primaryColor);
  color: #fff;
  font-weight: 500;
  margin: 10px 0 20px 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s all ease;
}

.login-form .login-container .btn:hover {
  background: #d11431;
}

.login-form .login-container .btn-light {
  width: 130px;
  height: 40px;
  border: 1.5px solid #9d6b73;
  outline: none;
  box-shadow: none;
  border-radius: 50px;
  background: #fff;
  color: #9d6b73;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s all ease;
}

.login-form .login-container .btn-light:hover {
  transform: scale(1.035);
  background: var(--bgHoverColor);
  color: var(--textLight);
  border-color: var(--textLight);
}

.login-form .login-container .social-text {
  margin: 20px 0 6px 0;
  font-size: 0.9rem;
  color: var(--textLight);
}

.login-form .login-container .social-media {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login-form .login-container .social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: var(--primaryColor);
  text-decoration: none;
  transition: all 0.3s ease-in;
}

.login-form .login-container a {
  text-decoration: none;
}

.login-form .login-container .social-icon:nth-child(1):hover {
  background: #273b65 !important;
}

.login-form .login-container .social-icon:nth-child(2):hover {
  background: #3c81b5 !important;
}

.login-form .login-container .social-icon:nth-child(3):hover {
  background: #8c1815 !important;
}

.login-form .login-container .social-icon:nth-child(4):hover {
  background: #000000 !important;
}

.login-form .login-container .panels-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.login-form .login-container .panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  min-width: 238px;
  padding: 0 10px;
  text-align: center;
  z-index: 6;
}

.login-form .login-container .left-panel {
  pointer-events: none;
}

.login-form .login-container .content {
  color: #fff;
  transition: 1.1s ease-in-out;
  transition-delay: 0.5s;
}

.login-form .login-container .panel h3 {
  font-size: 1.35rem;
  font-weight: 600;
}

.login-form .login-container .panel p {
  font-size: 1rem;
  font-weight: normal;
}

.login-form .login-container .image {
  transition: 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.login-form .login-container .left-panel .image,
.login-form .login-container .left-panel .content {
  transform: translateX(-300%);
}

.login-form .login-container .right-panel .image,
.login-form .login-container .right-panel .content {
  transform: translateX(0);
}

.login-form .login-container .account-text {
  display: none;
}

/* Animation */
.login-form .login-container.sign-up-mode::before {
  transform: translateX(0);
}

.login-form .login-container.sign-up-mode .right-panel .image,
.login-form .login-container.sign-up-mode .right-panel .content {
  transform: translateX(300%);
}

.login-form .login-container.sign-up-mode .left-panel .image,
.login-form .login-container.sign-up-mode .left-panel .content {
  transform: translateX(0);
}

.login-form .login-container.sign-up-mode form.sign-in-form {
  opacity: 0;
}

.login-form .login-container.sign-up-mode form.sign-in-form {
  opacity: 1;
}

.login-form .login-container.sign-up-mode .right-panel {
  pointer-events: none;
}

.login-form .login-container.sign-up-mode .left-panel {
  pointer-events: all;
}

/* Media Query */
@media (max-width: 992px) {
  .login-form {
    margin-top: 2.8rem;
    margin-bottom: 0;
  }
  .login-form .login-container {
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }
  .login-form .login-container .pageCloseBtn{
    display: none;
  }
}

@media (max-width: 768px) {
  .login-form .login-container::before {
    display: none;
  }

  .login-form .login-container form {
    width: 80%;
  }

  .login-form .login-container form.sign-up-form {
    display: none;
  }

  .login-form .login-container.sign-up-mode2 form.sign-up-form {
    display: flex;
    opacity: 1;
  }

  .login-form .login-container.sign-up-mode2 form.sign-in-form {
    display: none;
  }

  .login-form .login-container .panels-container {
    display: none;
  }

  .login-form .login-container .account-text {
    display: initial;
    margin-top: 30px;
  }
}

@media (max-width: 672px) {
  .login-form .login-container .password-validatity p {
    line-height: 12px;
  }
}

@media (max-width: 340px) {
  .login-form .login-container form {
    width: 90%;
  }
}

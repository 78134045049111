.fav-img img {
  border: 6px solid #efefef;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  height: 120px;
  width: 120px;
}

.favorite-doctor {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.favorite-doctor .profile-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 34%;
  width: 100%;
  border-radius: 23px 23px 0 0;
  background: #ffdbdb;
  border-bottom: 1.5px solid var(--borderLight);
}

.favorite-doctor .profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
  width: 100%;
  background: white;
  border-radius: 24px 24px 8px 8px;
  padding: 25px;
  border: 1.5px solid var(--borderLight);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  position: relative;
  margin: 1rem auto;

  -webkit-animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.favorite-doctor .image {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: var(--secondaryColor);
  margin-bottom: 10px;
  overflow: hidden;
}

.favorite-doctor .image .profile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}

.favorite-doctor .profile-card .text-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textColor);
}

.favorite-doctor .profile-card .text-data .name {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.favorite-doctor .profile-card .text-data .job {
  text-align: center;
  color: var(--textLight);
  font-size: 14px;
  font-weight: 500;
}

.favorite-doctor .actionBtn {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
}

.favorite-doctor .actionBtn a {
  padding: 8px 4px;
  width: 120px;
  border-radius: 5px;
  border: 1.5px solid var(--primaryColor);
  outline: none;
  font-size: 0.9rem;
  font-weight: normal;
  background: var(--primaryColor);
  color: white;
  margin: 1rem auto;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.favorite-doctor .actionBtn a:nth-child(2) {
  background: white;
  color: var(--primaryColor);
  font-weight: 500;
}

.star {
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .favorite-doctor .profile-card {
    max-width: 350px;
  }
}

@media (max-width: 576px) {
  .favorite-doctor .profile-card {
    max-width: none;
  }
}

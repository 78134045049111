/* Track Appointment Style */

.track-appointment .apt-heading {
  margin: 1rem;
}

.track-appointment .search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.track-appointment .search-bar .search-input {
  width: 450px;
}

@media (max-width: 576px) {
  .track-appointment .search-bar .search-input {
    width: 350px;
  }
}

@media (max-width: 412px) {
  .track-appointment .search-bar .search-input {
    width: 300px;
  }
}

@media (max-width: 328px) {
  .track-appointment .search-bar .search-input {
    width: 270px;
  }
}

/* Appointment Details */

.track-detail {
  padding: 30px 0;
  margin-bottom: 4rem;
}
.img-div {
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.track-detail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: none;
}

.profile-card {
  background: var(--bgColor);
  border: 1.5px solid var(--borderColor);
  border-radius: 12px;
  box-shadow: var(--shadow);
}

.profile-card .label {
  color: var(--textColor);
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.profile-card .name {
  color: var(--textColor);
  font-weight: 500;
  font-size: 1.15rem;
}

.profile-card .address {
  font-size: 1rem;
  font-weight: normal;
  color: var(--textLight);
}

.detail-card {
  margin-left: 2rem;
}

.detail-card .detail-label {
  color: var(--textColor);
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  border-bottom: 2px solid var(--textColor);
}

.detail-card h4 {
  color: var(--textColor);
  font-size: 1.25rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .detail-card {
    margin: 0;
    margin-top: 4rem;
  }
}

@media (max-width: 576px) {
  .track-detail {
    padding: 30px 16px;
  }
}

.achievement-card {
  background: #fff;
  box-shadow: var(--materialShadow);
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem auto;
  border: 1.5px solid var(--borderLight);
}

.achievement-img {
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
}

.achievement-img img {
  width: 70px;
}

.about-achievement {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.about-achievement h3 {
  font-size: 1.3rem;
  margin: 0.5rem;
  text-align: start;
}

.about-achievement small {
  font-size: 1rem;
  margin: 0.5rem;
  text-align: start;
  color: var(--textLight);
}

@media (max-width: 576px) {
  .achievement-card {
    padding: 1rem;
    margin: 0.5rem auto;
  }

  .achievement-img {
    padding: 0.75rem;
  }

  .achievement-img img {
    width: 50px;
  }

  .about-achievement h3 {
    font-size: 1.25rem;
    margin: 0.25rem;
  }

  .about-achievement small {
    font-size: 0.9rem;
    margin: 0.25rem;
  }
}

@media (max-width: 312px) {
  .achievement-card {
    padding: 0.75rem;
  }

  .about-achievement h3 {
    font-size: 1.15rem;
  }

  .about-achievement small {
    margin: 0.15rem;
  }
}

.doctor-form-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: var(--materialShadow);
  background-color: #ffffff;
}

.doctor-form-container .ant-form-item-label > label {
  font-weight: bold;
}

.doctor-form-container .ant-input {
  border-radius: 4px;
}

.doctor-form-container .ant-btn-primary {
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
}

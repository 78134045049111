.invoice-content {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 30px;
}
.invoice-item .invoice-logo {
  margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px;
}
.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: 600;
}
.invoice-item .invoice-details {
  text-align: right;
  color: #757575;
  font-weight: 500;
}
.invoice-item .invoice-details strong {
  color: #272b41;
}
.invoice-item .invoice-details-two {
  text-align: left;
}
.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px;
}
.invoice-item .invoice-text h2 {
  font-weight: 400;
}
.invoice-info {
  margin-bottom: 30px;
}
.invoice-info p {
  margin-bottom: 0;
}
.invoice-info.invoice-info2 {
  text-align: right;
}
.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
  color: #272b41;
  font-weight: 600;
  padding: 10px 20px;
  line-height: inherit;
}
.invoice-table tr td,
.invoice-table-two tr td {
  color: #757575;
  font-weight: 500;
}
.invoice-table-two {
  margin-bottom: 0;
}
.invoice-table-two tr th,
.invoice-table-two tr td {
  border-top: 0;
}
.invoice-table-two tr td {
  text-align: right;
}
.invoice-info h5 {
  font-size: 16px;
  font-weight: 500;
}
.other-info {
  margin-top: 10px;
}

.print-btn {
  margin-right: 10rem;
}

@media (max-width: 992px) {
  .print-btn {
    margin-right: 1rem;
  }
}

@media (max-width: 576px) {
  .invoice-info.invoice-info2 {
    text-align: left;
  }

  .invoice-item .invoice-details {
    text-align: left;
  }
}

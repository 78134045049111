.announcements {
  margin-top: 5rem;
}

.announcements .announce-container {
  justify-content: space-around;
  gap: 2rem;
}

.announcements .announce-container .announceCard {
  flex: 0 0 22%;
  height: 320px;
  text-align: center;
  padding: 2rem 0.5rem 1rem 0.5rem;
  border-radius: 50% 50% 0 0;
  border-top: 2px solid var(--primaryColor);
  border-bottom: 2px solid var(--primaryColor);
}

.announcements .announce-container .announceCard.card1 {
  border-color: #9355e6;
}
.announcements .announce-container .announceCard.card2 {
  border-color: #ff6600;
}
.announcements .announce-container .announceCard.card3 {
  border-color: #4fcf6c;
}
.announcements .announce-container .announceCard.card4 {
  border-color: #c7bb35;
}

.announcements .announce-container .announceCard > i {
  width: 6rem;
  height: 6rem;
  font-size: 3.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--borderColor);
  padding: 10px;
  border-radius: 50%;
  background: var(--bg-gradient);
  box-shadow: var(--shadowAll);
}

.announcements .announce-container .announceCard > i.icon1 {
  color: #5174dd;
  border-color: rgba(62, 89, 170, 0.35);
}
.announcements .announce-container .announceCard > i.icon2 {
  color: #dda552;
  border-color: rgba(170, 126, 63, 0.35);
}
.announcements .announce-container .announceCard > i.icon3 {
  color: #dd5151;
  border-color: rgba(170, 62, 62, 0.35);
}
.announcements .announce-container .announceCard > i.icon4 {
  color: #51dd9c;
  border-color: rgba(62, 170, 119, 0.35);
}

.announcements .announce-container .announceCard h3 {
  color: var(--textColor);
  font-size: 1.3rem;
  text-transform: capitalize;
  margin: 1rem 0;
  font-weight: bold;
}

.announcements .announce-container .announceCard p {
  font-size: 0.9rem;
  text-align: justify;
  margin-top: 20px;
  text-align: justify;
}

@media (max-width: 1100px) {
  .announcements .announce-container {
    gap: 4rem;
  }
  .announcements .announce-container .announceCard {
    flex: 0 0 33%;
  }
}

@media (max-width: 992px) {
  .announcements .announce-container {
    gap: 3rem 1rem;
  }
  .announcements .announce-container .announceCard {
    flex: 0 0 36%;
  }
}

@media (max-width: 768px) {
  .announcements .announce-container {
    gap: 3rem;
  }
  .announcements .announce-container .announceCard {
    flex: 0 0 50%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .announcements .announce-container {
    gap: 2rem 1rem;
  }
  .announcements .announce-container .announceCard {
    flex: 0 0 50%;
  }
}

@media (max-width: 518px) {
  .announcements .announce-container .announceCard {
    flex: 0 0 60%;
  }
}

@media (max-width: 412px) {
  .announcements .announce-container .announceCard {
    flex: 0 0 70%;
  }
}

@media (max-width: 382px) {
  .announcements .announce-container .announceCard {
    flex: 0 0 80%;
  }
}

@media (max-width: 352px) {
  .announcements .announce-container .announceCard {
    flex: 0 0 90%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Clicker+Script&family=Pacifico&family=Poppins:wght@200;300;400;500;600&family=Satisfy&family=Titillium+Web&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

:root {
  --primaryColor: #ec1839;
  --primaryHoverColor: #b9122c;
  /* --secondaryColor: #ec98a5; */
  --secondaryColor: #ffdbdb;
  --bgColor: #fdf9ff;
  --bgHoverColor: #f2f2fc;
  /* --bgLight: #fdf9ff; */
  --textColor: #302e4d;
  --textLight: #58567c;
  /* --headingColor: #2c4964; */
  --borderColor: #d4cad9;
  --borderLight: #dfd8e3;
  /* --skin-gradient: linear-gradient(97.05deg, #ec1839 3.76%, #d01532 100%); */
  --bg-gradient: linear-gradient(145deg, #ececec, #ffffff);
  --shadow: 0px 23px 21px -8px rgba(167, 167, 167, 0.15);
  --shadowAll: 0px 2px 15px rgba(0, 0, 0, 0.15);
  --shadowAllLight: 0px 4px 15px rgba(0, 0, 0, 0.1);
  --materialShadow: 0 2.5px 2px -1px rgba(32, 31, 31, 0.45);
  --line-color: #58567c !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  font-family: "Poppins";
}

.ant-btn-primary {
  background: #ec1839 !important;
  color: #fff !important;
  transition: all 0.5s ease !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-btn-primary:hover {
  background: #b9122c !important ;
}

/* Edit Scrollbar */
::-webkit-scrollbar {
  height: 0;
  width: 0.2rem;
}

::-webkit-scrollbar-track {
  background-color: var(--bgColor);
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 5rem;
}

.swiper-pagination-bullet-active {
  background: var(--primaryColor) !important;
}

.swiper-wrapper {
  margin-bottom: 50px !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  height: 100%;
  width: 100%;
  color: #272b41;
  background: #fff;
}

/* Common Classes */
.text-form-input-field {
  width: 100%;
  border: 1.5px solid var(--borderLight);
  border-radius: 25px;
  background: var(--bgColor);
  padding: 10px 25px;
  font-size: 16px;
  color: var(--textLight);
  transition: all 0.3s ease;
}

.text-form-input-field:focus {
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15);
  border: 1.5px solid var(--borderColor);
}

textarea.text-form-input-field {
  height: 168px;
}

.margin-top-max {
  margin-top: 4.5rem !important;
}

.margin-top-ultra-max {
  margin-top: 6rem !important;
}

.my-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  color: white;
  border-radius: 1.5rem;
  display: inline-block;
  white-space: nowrap;
  border: none;
  background: var(--skin-gradient);
  transition: all 0.3s ease;
}

.my-btn:hover {
  transform: scale(1.025);
}

.button {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--primaryColor);
  border: none;
  border-radius: 6px;
  transition: all 300ms ease-in;
  cursor: pointer;
}

.button:hover {
  color: white;
  background: #b9122c;
}

.copyBtn {
  background: #fff;
  color: var(--textColor) !important;
}

.copyBtn:hover {
  color: var(--textColor) !important;
  border-color: var(--borderColor) !important;
  background: var(--bgHoverColor) !important;
}

.heading {
  color: var(--textColor);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.3rem;
}

.sub-heading {
  font-size: 1.5rem;
  font-weight: normal;
}

.paragraph {
  font-size: 1rem;
  color: var(--textLight) !important;
  margin: 1rem !important;
  text-align: justify;
}

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexRowCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexRowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexRowStart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: var(--primaryColor);
  font-size: 1.5rem;
  font-weight: 600;
}

/* Default Style */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--textColor);
  font-weight: 500;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.875rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

a {
  color: #2e3842;
  text-decoration: none;
  transition: all 0.35s ease;
}

a:hover {
  color: var(--secondaryColor);
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

.ant-radio-group {
  display: flex !important;
  flex-direction: column !important;
}

.card-label > label {
  background-color: #f8f9fa;
  color: #959595;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin: 6px auto auto 8px;
  padding: 0 7px;
}

.card-label > input {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 50px;
  margin-top: -13px;
  padding: 5px 15px 0;
  transition: border-color 0.3s;
  width: 100%;
}

/* .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: var(--primaryColor);
} */

/* .btn-get-started:hover {
  background: var(--primaryColor);
} */

.section-title h2 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
  position: relative;
  color: var(--textColor);
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--primaryColor);
  bottom: 0;
  left: calc(50% - 20px);
}

.more-btn {
  display: inline-block;
  border: 1px solid var(--primaryColor);
  padding: 6px 30px 8px 30px;
  color: var(--primaryColor);
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.more-btn .icon {
  font-size: 14px;
}

.more-btn:hover {
  color: #fff;
  background: var(--primaryColor);
}

.custom-blog-card:hover {
  transform: scale(1.1);
}

.custom-blog-card {
  transition: 0.5s ease-in;
  max-width: 18rem;
}

.text-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #2c4964;
}

.change-photo-btn {
  background-color: var(--primaryColor);
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  padding: 5px 10px;
  position: relative;
  transition: 0.35s ease-in;
  text-align: center;
}

.change-photo-btn:hover {
  background-color: var(--primaryHoverColor);
  border-radius: 50px;
  color: #fff;
}

.change-photo-btn input.upload {
  bottom: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .flexRowSpaceBetween {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media only screen and (max-width: 576px) {
  .flexRowSpaceBetween {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

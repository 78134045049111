.our-doctors .profile-card {
  height: 400px;
}

.our-doctors .profile-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 36%;
  width: 100%;
  border-radius: 23px 23px 0 0;
  background: #ffdbdb;
  border-bottom: 1.5px solid var(--borderLight);
}

.our-doctors .profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  background: white;
  border-radius: 24px 24px 8px 8px;
  padding: 25px;
  border: 1.5px solid var(--borderLight);
  box-shadow: var(--materialShadow);
  position: relative;
  margin: 1rem auto;
}

.our-doctors .image {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: var(--secondaryColor);
  margin-bottom: 10px;
  overflow: hidden;
}

.our-doctors .image .profile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}

.our-doctors .profile-card .text-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.our-doctors .profile-card .text-data .name {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.our-doctors .profile-card .text-data .job {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.our-doctors .actionBtn {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.our-doctors .actionBtn a {
  padding: 8px 4px;
  width: 120px;
  border-radius: 5px;
  border: 1.5px solid var(--primaryColor);
  outline: none;
  font-size: 0.9rem;
  font-weight: normal;
  background: var(--primaryColor);
  color: white;
  margin: 1rem auto;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.our-doctors .actionBtn a:nth-child(2) {
  background: white;
  color: var(--primaryColor);
  font-weight: 500;
}

.our-doctors .profile-card .media-buttons {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.our-doctors .profile-card .media-buttons .link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin: 0 8px;
  background-color: var(--primaryColor);
  text-decoration: none;
  transition: all 0.3s ease-in;
}

.our-doctors .profile-card .media-buttons .link:hover {
  transform: scale(1.1);
}

.star {
	font-size: 14px !important;
}
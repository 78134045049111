.my-patient .profile-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 30%;
  width: 100%;
  border-radius: 11px 11px 4px 4px;
  background: #ffdbdb;
  border-bottom: 1.5px solid var(--borderLight);
}

.my-patient .profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
  background: white;
  border-radius: 12px 12px 4px 4px;
  padding: 10px 15px;
  border: 1.5px solid var(--borderColor);
  box-shadow: var(--materialShadow);
  position: relative;
  margin: 1rem auto;
  -webkit-animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.my-patient .image {
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background: white;
  margin-bottom: 10px;
  overflow: hidden;
}

.my-patient .image .profile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}

.my-patient .profile-card h5{
  margin: 1rem;
  color: var(--textColor);
  font-size: 1.2rem;
  font-weight: bold;
}

.my-patient .profile-card .text-data {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  text-align: center;
  color: var(--textLight);
  font-size: 12px;
  font-weight: 500;
  gap: 20px;
}

.my-patient .profile-card .icon {
  color: var(--textColor);
  min-width: 24px;
  max-width: 24px;
}

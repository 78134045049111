/* Dashboard Change Password CSS */

.change-password .text-input-field {
  width: 70%;
  border: 1.5px solid var(--borderLight);
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 16px;
  color: var(--textLight);
  transition: all 0.3s ease;
}

.change-password .text-input-field:focus {
  box-shadow: 0 0 30px rgba(16, 15, 27, 0.15);
  border: 1.5px solid var(--borderColor);
}

@media (max-width: 576px) {
  .change-password .text-input-field {
    width: 100%;
  }
}

/* Actual Change Password CSS */

.reset-password {
  margin-top: 8rem;
  margin-bottom: 8rem;
  min-width: 450px;
}

@media (max-width: 576px) {
  .reset-password {
    min-width: 90%;
  }
}
